<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card id="CounsellingApp">
          <template v-slot:headerTitle>
            <b-row class="row mt-2">
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-6">
                <h4 class="mb-3"><i class="fa fa-solid ri-user-star-fill primary-color" aria-hidden="true"></i>&nbsp;{{cvCardTitle}}</h4>
              </b-col>
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="head_buttons">
                  <b-button class="mr-2 mb-2" variant="primary" @click="showCreateSession">{{cvbtnCreateSession}}</b-button>
                  <b-button variant="primary mr-2 mb-2" @click="goToCalendar">{{cvbtnBlockCalendar}}</b-button>
                  <b-button class="mr-2 mb-2" variant="primary" @click="gotoFirefliesDashboard">{{cvbtnFirefliesDashboard}}</b-button>
                  <b-button class="mr-2 mb-2" variant="primary" @click="gotoSpcmCounsellorDashboard">{{cvbtnSpcmCounsolerDashboard}}</b-button>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <b-col sm="4" md="6" lg="3" xl="4" class="mb-2">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <!-- Counselor Name Filter -->
              <b-col sm="4" md="6" lg="3" xl="2" class="mb-2" v-if="userData.user_role == 'USERROLE11111'">
                <select v-model="whereFilter.counsellor_id" class="form-control" @change="setCounsellor" v-if="counsellorObjList.length > 0" title="Counselor List">
                  <option :value="null" disabled>Choose Counselor</option>
                  <option :value=null>All</option>
                  <option v-for="(counsellor, index) of counsellorObjList" :key="(index+1)" :value="counsellor.counsellor_user_id">
                    {{counsellor.user_name}}
                  </option>
                </select>
              </b-col><!-- Counselor Name Filter -->

              <!-- SPCM Category Ex. GIDE ONE, EXCLUSIVE Etc -->
              <b-col sm="4" md="5" lg="3" xl="2" class="mb-2">
                <select v-model="whereFilter.spcm_category" class="form-control" @change="spcmList()">
                  <option :value="null" disabled>Choose Category</option>
                  <option :value=null>All</option>
                  <option v-for="(category, index) of SPCM_CATEGORY_LIST" :key="(index+1)" :value="category.spcm_category">
                    {{category.spcm_category}}
                  </option>
                </select>
              </b-col><!-- SPCM Category -->

              <!-- Counselling Status -->
              <b-col sm="4" md="5" lg="2" xl="2" class="mb-2">
                <select v-model="whereFilter.spcm_state" class="form-control" @change="spcmList()">
                  <option :value="null" disabled>Choose Status</option>
                  <option :value=null>All</option>
                  <option v-for="(state, index) of SPCM_STATE_LIST" :key="(index+1)" :value="index">
                    {{state}}
                  </option>
                </select>
              </b-col><!-- Counselling Status -->

              <!-- Download CSV button -->
              <b-col sm="2" md="2" lg="1" xl="2" class="pr-0 mb-3 float-right">
                <b-btn variant="primary" class="Download_button" @click="backendCsvDownload"> CSV</b-btn>
              </b-col>
              <!-- Download CSV button -->
            </b-row>
            <b-row :key="updateInfo">
              <b-col class="container_minheight" md="12" v-if="spcmObjList && spcmObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="spcmObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template v-slot:cell(id)="data">
                    <span style="display: block;width: 50px" title="View Session Details">{{spcmObjList.indexOf(data.item)+1}}</span>
                  </template>

                  <template v-slot:cell(user_name)="data">
                    <span class="pointer" @click="viewDetails(`/user/${data.item.user_id}`)"> {{ data.item.user_name }}</span><br>
                    <span><small>
                      Id:&nbsp;{{ data.item.user_id }}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(data.item.user_id)"></i><br>
                      Email:&nbsp;{{ data.item.user_email }}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Email" @click="doCopy(data.item.user_email)"></i><br>
                      Mobile:&nbsp;{{data.item.user_mobile}}<br><br>
                      Class:&nbsp;{{ data.item.class_name }}<br>
                      School:&nbsp;{{ data.item.org_name }}<br>
                      {{data.item.org_city ? data.item.org_city : '' }}
                      {{data.item.org_state ? ', ' + data.item.org_state : '' }}
                      {{data.item.org_country ? ', ' + data.item.org_country : '' }}<br>
                    </small></span>
                    <div class="mt-2">
                      <b-badge title="Change Student's Counselling Plan" class="text-wrap pointer mr-4" :style="'background:'+getBgFor(data.item.spcm_category)" @click="showSpcmCategoryUpdateOpenDialog(data.item)">{{ data.item.spcm_category }}</b-badge>
                      <b-badge variant="success" v-if="data.item.spcm_tnc == '1'" class="mb-2 ml-1" title="Counseling TnC Accepted">
                       TnC Accepted
                      </b-badge>
                      <b-badge style="background: #e5252c!important" v-if="data.item.spcm_tnc == '0'" title="Counseling TnC Not Accepted"  class="mb-2 ml-1">
                        TnC Not Accepted
                      </b-badge>
                    </div>
                    <div class="button_align mt-2">
                      <template v-if="adminView">
                        <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="showChangeCounsellor(data.item)" size="sm" title="Change Strategist"><i class="fa fa-shuffle font-size-20 primary-color" aria-hidden="true"></i></b-button>
                        <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="spcmStudentCounsellorTeam('MODAL', data.item)" size="sm" title="View Team"><i class="fa-solid fa-users font-size-20 primary-color" aria-hidden="true"></i></b-button>
                        <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="showSpcmDeleteDialog(data.item)" size="sm"><i class="fa-solid fa-trash font-size-20 primary-color" aria-hidden="true"></i></b-button>
                        <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="openReport(data.item.report)" v-if="data.item.report" size="sm" title="View GPaths Report"><i class="fa-solid fa-map-signs font-size-20 primary-color" aria-hidden="true"></i></b-button>
                        <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="spcm_country_strategyAdd('MODAL', data.item)" size="sm" title="Update Country Strategy"><i class="fa fa-map-marker font-size-20 primary-color" aria-hidden="true"></i></b-button>
                        <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="viewSessionDetails(data.item.spcm_id)" size="sm" title="View Session Details"><i class="ri-user-star-fill font-size-20 primary-color" aria-hidden="true"></i></b-button>
                        <b-button v-if="VUE_APP_ENV !== 'prod'" class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="viewContract(data.item)" size="sm" title="GIDE Counseling Contract"><i class="fa-solid fa-file-contract font-size-20 primary-color" aria-hidden="true"></i></b-button>
                      </template>
                      <template v-else-if="counsellorView">
                        <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="showChangeCounsellor(data.item)" size="sm" title="Change Strategist"><i class="fa fa-shuffle font-size-20 primary-color" aria-hidden="true"></i></b-button>
                        <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="spcmStudentCounsellorTeam('MODAL', data.item)" size="sm" title="View Team"><i class="fa-solid fa-users font-size-20 primary-color" aria-hidden="true"></i></b-button>
                        <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="openReport(data.item.report)" v-if="data.item.report" size="sm" title="View GPaths Report"><i class="fa-solid fa-map-signs font-size-20 primary-color" aria-hidden="true"></i></b-button>
                        <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="spcm_country_strategyAdd('MODAL', data.item)" size="sm" title="Update Country Strategy"><i class="fa fa-map-marker font-size-20 primary-color" aria-hidden="true"></i></b-button>
                        <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="viewSessionDetails(data.item.spcm_id)" size="sm" title="View Session Details"><i class="ri-user-star-fill font-size-20 primary-color" aria-hidden="true"></i></b-button>
                        <b-button v-if="VUE_APP_ENV !== 'prod'" class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="viewContract(data.item)" size="sm" title="GIDE Counseling Contract"><i class="fa-solid fa-file-contract font-size-20 primary-color" aria-hidden="true"></i></b-button>
                      </template>
                    </div>
                    <div class="pointer" @click="showChangeCounsellor(data.item)"><small>Strategist: <span class="primary-color">:&nbsp;<span>{{ data.item.counsellor_email }}</span></span></small></div>
                  </template>

                  <template v-slot:cell(user_program_test)="data">
                    <div v-for="(examObj, examName) in getProgramAndTest(data.item.user_program_test)" :key="examName" class="mb-0">
                      <div v-if="examObj.total_score" class="mb-1 p-0">
                        <h6>
                          {{ examObj.exam_name }} <span v-if="examObj.subject_name">({{ examObj.subject_name }})</span>: {{ examObj.total_score }}
                        </h6>
                        <small>{{ examObj.exam_date }}</small><br>
                      </div>
                    </div>
                  </template>

                  <template v-slot:cell(spcm_state)="data">
                    <b-badge title="Change Status" @click="showSpcmStatusUpdateOpenDialog(data.item)" class="text-wrap pointer" :style="getCounsellingStateColorCode(data.item.spcm_state)">{{ SPCM_STATE_LIST[data.item.spcm_state] }}</b-badge>
                  </template>

                  <template v-slot:cell(ms_country_strategy)="data">
                    {{ data.item.ms_country_strategy}}
                  </template>

                  <template v-slot:cell(spcm_id)="data">
                    <span v-if="data.item.latest_counselor_notes && data.item.latest_counselor_notes.length > 0" @click='viewSessionDetails(data.item.spcm_id)' v-html="getNotes(data.item)"></span>
                    <span v-else>
                      <span @click='viewSessionDetails(data.item.spcm_id)' class="primary-color pointer">
                        <b-badge>Add Notes</b-badge>
                      </span>
                    </span>
                  </template>

                  <template v-slot:cell(product)="data">
                    <div class="pointer" v-for="(pdt, index) of userSubsPdtMap[data.item.user_id].split(',')" :key="index" @click="goToUserSubscriptions(data.item.user_id)">
                      <b-badge v-if="pdt.length > 0" :style="getStateColorCode()" class="mb-2">
                        {{pdt}}
                      </b-badge><br>
                    </div>
                  </template>

                  <template v-slot:cell(modified_on)="data">
                    <span>{{ data.item.modified_on | dateFormatDDMMYYYY }}</span>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <!-- Spcm Create Your Custom Session -->
    <b-modal
      v-model="showSessionCreationModal"
      scrollable
      :title="cvModalTitle"
      size="xxl"
      id="inviteModal"
    >
      <InviteUser :propProductCode="'spcm'" :propProductName="'Counselor'" :propOpenedInModal="true" :propCounsellor="userData" @emitCloseInviteUser="emitCloseInviteUser"/>
      <template #modal-footer="">
        <b-button size="sm" @click="closeCreateSession()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>
    <!-- Spcm Create Your Custom Session  -->

    <!-- Allot student a different counsellor -->
    <b-modal
      v-model="showChangeCounsellorModal"
      scrollable
      :title="cvChangeCounsellorModalTitle"
      size="lg"
    >
      <ChangeCounsellor :propOpenedInModal="true" :propUserObj="propSpcmUser" :propPdtCode="'spcm'" @emitCloseChangeCounselllor="emitCloseChangeCounselllor" />
      <template #modal-footer="">
        <b-button size="sm" @click="emitCloseChangeCounselllor()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>
    <!-- Allot student a different counsellor -->

    <!-- Update SPCM Status -->
    <b-modal v-model="showModelSpcmStatusUpdate" size="sm" title="Update Status" ok-title="Update" cancel-title="Cancel">
        <!-- Counselling Status -->
        <b-col sm="12" md="12" lg="12" xl="12" class="mb-2">
          <select v-model="whereFilter.spcm_state" class="form-control">
            <option :value="null" disabled>Choose Status</option>
            <option v-for="(state, index) of SPCM_STATE_LIST" :key="(index+1)" :value="index">
              {{state}}
            </option>
          </select>
        </b-col><!-- Counselling Status -->

      <template #modal-footer="">
        <b-button size="sm" @click="showModelSpcmStatusUpdate = false">{{cvbtnModalCancel}}</b-button>
        <b-button size="sm" variant="primary" @click="spcmStatusUpdate(spcmObj, whereFilter.spcm_state)">Update</b-button>
      </template>
    </b-modal><!-- Update SPCM Status -->

    <b-modal v-model="showModelSpcmDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelSpcmDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="spcmDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <!-- Add Spcm Country Strategy-->
    <b-modal
      v-model="showModelSpcm_Country_StrategyAdd"
      scrollable
      :title="cvAddStrategyModalHeader">
      <spcmCountryStrategyAdd :propOpenedInModal="true" :propstrategyObj="spcmStrategyObj" @emitCloseSpcmStretegyModal="closeSpcmCountryStrategyModal"/>
    </b-modal><!-- Add Spcm Country Strategy-->

    <!--Spcm Student Counsellor Team -->
    <b-modal
      v-model="showModelSpcmStudentCounsellorTeam"
      scrollable
      :title="cvSpcmStudentCounsellorTeamModalHeader"
      size="xxl"
      hide-footer>
      <SpcmStudentCounsellorTeam :propStudentCounselorTeam="true" :propOpenedInModal="true" :propStudentCounsellorObj="StudentCounsellorTeamObj" @emitCloseStudentCounsellorTeamModal="closeStudentCounsellorTeamModal" />
    </b-modal><!-- Spcm Student Counsellor Team -->

     <!-- Update SPCM Category -->
     <b-modal v-model="showModelSpcmCategoryUpdate" size="sm" title="Change Student's Counselling Plan" ok-title="Update" cancel-title="Cancel">
        <b-col sm="12" md="12" lg="12" xl="12" class="mb-2">
          <select v-model="new_spcm_category" class="form-control">
            <option :value="null" disabled>Choose Category</option>
            <option v-for="(category, index) of SPCM_CATEGORY_LIST" :key="(index+1)" :value="category.spcm_category">
              {{category.spcm_category}}
            </option>
          </select>
        </b-col>
        <template #modal-footer="">
        <b-button size="sm" @click="showModelSpcmCategoryUpdate = false">{{cvbtnModalCancel}}</b-button>
        <b-button size="sm" variant="primary" @click="spcmCategoryUpdate(spcmObj, new_spcm_category)">Change</b-button>
      </template>
    </b-modal><!-- Update SPCM Categor -->

    <!-- Gide Contract -->
    <b-modal
      v-model="showModalGideContracts"
      scrollable
      size="xxl"
      title="Gide Contract"
    >
      <GideContract :propOpenedInModal="true" :propGideContractObj = "gideCounselingContractObj" @emitcloseGIDECounselingContractModal="closeGIDECounselingContractModal"/>
      <template #modal-footer="">
        <b-button size="sm" @click="closeGIDECounselingContractModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>
    <!-- Gide Contract -->

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { Spcms } from "../../../FackApi/api/Spcm.js"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"
import { socialvue } from "../../../config/pluginInit.js"
import InviteUser from "../../../components/InviteUser.vue"
// import { SpcmNotess } from "../../../FackApi/api/spcmNotes"
import { SpcmSessions } from "../../../FackApi/api/spcmSession.js"
import userPermission from "../../../Utils/user_permission.js"
import spcmCountryStrategyAdd from "./spcmCountryStrategyAdd.vue"
import { SpcmCounsellors } from "../../../FackApi/api/SpcmCounsellor.js"
import SpcmStudentCounsellorTeam from "./SpcmStudentCounsellorTeam.vue"
import ChangeCounsellor from "./SpcmChangeCounsellor.vue"
import ColorCodes from "../../../Utils/colorcodes.js"
import GideContract from "./GideContract.vue"
import GideContractJSON from "../../../FackApi/json/GideContract.json"

export default {
  name: "SpcmList",
  components: {
    ChangeCounsellor,
    InviteUser,
    spcmCountryStrategyAdd,
    SpcmStudentCounsellorTeam,
    GideContract
  },
  data () {
    return {
      VUE_APP_ENV: process.env.VUE_APP_ENV,
      apiName: "spcm_list",
      cvCardTitle: "Counseling Dashboard",
      cvbtnFirefliesDashboard: "Fireflies",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvAddModalHeader: "Invite",
      cvAddStrategyModalHeader: "Add/Edit Country Strategy",
      cvbtnSpcmCounsolerDashboard: "Counseling Team",
      cvSpcmStudentCounsellorTeamModalHeader: "Student Counselor Team",
      cvChangeCounsellorModalTitle: "Change Strategist",
      showChangeCounsellorModal: false,
      cvbtnModalCancel: "Close",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Counselling",
      showModelSpcmAdd: false,
      showModelSpcmDelete: false,
      showModelSpcm_Country_StrategyAdd: false,
      showModelSpcmStudentCounsellorTeam: false,
      sortBy: "name",
      sessionList: [],
      sortDesc: false,
      columns: [],
      spcmObjList: null,
      StudentCounsellorTeamObj: null,
      spcmStrategyObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      spcmObj: null,
      whereFilter: {
        counsellor_id: "null",
        spcm_state: "null",
        spcm_category: "null"
      },
      counsellorObjList: [],
      adminView: false,
      counsellorView: false,
      counsellorId: null,
      new_spcm_category: null,
      cvbtnCreateSession: "Invite",
      cvbtnBlockCalendar: "Calendar",
      cvModalTitle: "Invite",
      showSessionCreationModal: false,
      showModelSpcmStatusUpdate: false,
      showModelSpcmCategoryUpdate: false,
      SPCM_STATE_LIST: {},
      SPCM_CATEGORY_LIST: {},
      SPCM_STATE_COLORCODE_LIST: {},
      updateInfo: 0,
      propSpcmUser: {},
      userSubsPdtMap: {},
      cvbtnGideContract: "View GIDE Counseling Contract",
      showModalGideContracts: false,
      gideCounselingContractObj: null,
      gideContractJSON: GideContractJSON,
      csvTitle: "CounselingDashboard"
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()

    if (this.userData.user_role == "USERROLE11111") {
      this.adminView = true
    }
    else if (this.userData.user_role == "USERROLE11118") {
      this.adminView = false
      this.counsellorView = true
      this.counsellorId = this.userData.user_id
    }

    this.spcmStateList()
    this.spcmList()
    this.getCounselorFilterList()
    this.setUserView()
    this.spcmCategory()
  },
  methods: {
    /**
     * get Counselling State Color Code
     */
    getCounsellingStateColorCode (stateId) {
      if (stateId == 99) {
        return `vertical-align: middle; color: #FFF; background: orange !important;`
      }
      else if (stateId == 98) {
        return `vertical-align: middle; color: #FFF; background: #e5252c !important;`
      }
      else {
        return `vertical-align: middle; color: #FFF; background: ${this.SPCM_STATE_COLORCODE_LIST[stateId]} !important;`
      }
    },
    /**
     * setCounsellor
    */
    async setCounsellor () {
      this.spcmList()
    },
    /**
     * emitCloseInviteUser
     * User Invited from Counseling Dashboard
     */
    async emitCloseInviteUser (inviteAddResp, slot, otherEmail = null) {
      if (slot) {
        let payload = {
          user_id: this.userData.user_id,
          participant_id: inviteAddResp.resp_data.user.user_id,
          session_title: `Session ${this.sessionList.length + 1}`,
          spcm_id: inviteAddResp.resp_data.spcm.spcm_id,
          session_start_ts: slot.slot_start_ts,
          session_end_ts: Number(slot.slot_start_ts) + slot.slot_duration,
          counsellor_id: this.userData.user_id,
          spcm_calendar_id: slot.spcm_calendar_id,
          session_state: null,
          source: "spcm" + this.userData.user_id,
          user_email: inviteAddResp.resp_data.user.user_email
        }

        if (otherEmail) {
          payload.other_email_id = otherEmail
        }

        let sessionAddResponse = await SpcmSessions.spcmSessionAdd(this, payload)
        if (!sessionAddResponse.resp_status) {
          this.toastMsg = sessionAddResponse.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
          return
        }
      }

      this.showSessionCreationModal = false
      this.spcmList()
    },
    /**
     * emit Close Change Counselllor
     */
    emitCloseChangeCounselllor () {
      this.spcmList()
      this.showChangeCounsellorModal = false
    },
    /**
     * goToCalendar
     */
    goToCalendar () {
      this.$router.push(`spcmCalendar_list/${this.userData.user_id}`)
    },
    /**
     * viewDetails
     */
    viewDetails (path) {
      window.open(path, "_blank")
    },
    /**
     * showCreateSession
     */
    showCreateSession () {
      this.showSessionCreationModal = true
    },
    /**
     * gotoFirefliesDashboard
     */
    gotoFirefliesDashboard () {
      this.$router.push(`FireFliesList`)
    },
    /**
     * closeCreateSession
     */
    closeCreateSession () {
      this.showSessionCreationModal = false
    },
    /**
     * viewSessionDetails
     */
    viewSessionDetails (spcmSessionId) {
      this.$router.push(`/spcm/${spcmSessionId}`)
    },
    /**
     * gotoSpcmCounsellorDashboard
    */
    gotoSpcmCounsellorDashboard () {
      this.$router.push(`/spcm_counseling_team`)
    },
    /**
     * setUserView
     */
    setUserView () {
      switch (this.userData.user_role) {
        case "USERROLE11111":
          this.columns = [
            { label: "# ", key: "id", class: "text-left align-text-top w-50px", sortable: true },
            { label: "Student Info", key: "user_name", class: "text-left align-text-top w-300px", sortable: true },
            { label: "Test Score", key: "user_program_test", class: "text-left align-text-top w-200px", sortable: false },
            { label: "Status", key: "spcm_state", class: "text-left align-text-top w-125px", sortable: true },
            { label: "Country Strategy", key: "ms_country_strategy", class: "text-left align-text-top w-125px", sortable: true },
            { label: "Current Progress", key: "spcm_id", class: "text-left align-text-top w-300px", sortable: true },
            { label: "Product Subscription ", key: "product", class: "text-left align-text-top", sortable: true },
            { label: "Updated On", key: "modified_on", class: "text-left align-text-top", sortable: true }
          ]
          break
        case "USERROLE11118":
          this.columns = [
            { label: "# ", key: "id", class: "text-left align-text-top w-50px", sortable: true },
            { label: "Student Info", key: "user_name", class: "text-left align-text-top text-wrap w-300px", sortable: true },
            { label: "Test Score", key: "user_program_test", class: "text-left align-text-top w-200px", sortable: false },
            { label: "Status", key: "spcm_state", class: "text-left align-text-top w-125px", sortable: true },
            { label: "Country Strategy", key: "ms_country_strategy", class: "text-left align-text-top w-125px", sortable: true },
            { label: "Current Progress", key: "spcm_id", class: "text-left align-text-top w-300px", sortable: true },
            { label: "Product Subscription ", key: "product", class: "text-left align-text-top", sortable: true },
            { label: "Updated On", key: "modified_on", class: "text-left align-text-top", sortable: true }
          ]
      }
    },
    /**
     * Calling Function as per whereFilter
    */
    async getspcmDateWise () {
      this.spcmList()
    },
    /**
     * getCounselorFilterList
     */
    async getCounselorFilterList () {
      const counselorsListResp = await SpcmCounsellors.spcmCounsellorList()
      if (counselorsListResp.resp_status) {
        this.counsellorObjList = counselorsListResp.resp_data.data
      }
    },
    /**
     * spcmStateList
     */
    async spcmStateList () {
      try {
        let spcmStateListResp = await Spcms.spcmStateList(this)
        if (spcmStateListResp.resp_status) {
          let stateObjList = spcmStateListResp.resp_data.data
          for (let stateObj of stateObjList) {
            this.SPCM_STATE_LIST[stateObj.state_id] = stateObj.state
            this.SPCM_STATE_COLORCODE_LIST[stateObj.state_id] = stateObj.state_color_code
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmStateList() and Exception:", err.message)
      }
    },
    /**
     * spcmList
     */
    async spcmList (downloadCsv = false) {
      try {
        if (this.counsellorId) {
          this.whereFilter.counsellor_id = this.counsellorId
        }
        this.whereFilter.downloadCsv = downloadCsv

        let spcmListResp = await Spcms.spcmList(this, this.whereFilter)
        if (spcmListResp.resp_status) {
          if (downloadCsv) {
            window.open(spcmListResp.resp_csv_file_url)
            return
          }

          this.spcmObjList = spcmListResp.resp_data.data
          this.totalRows = spcmListResp.resp_data.count
          this.userSubsPdtMap = spcmListResp.user_product_map
        }
        else {
          this.toastMsg = spcmListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }

        this.updateInfo = this.updateInfo + 1
      }
      catch (err) {
        console.error("Exception occurred at spcmList() and Exception:", err.message)
      }
    },
    /**
     * spcm Status Update
     */
    async spcmStatusUpdate (spcmObj, newState) {
      try {
        this.cvLoadingStatus = true

        let payload = {
          spcm_id: spcmObj.spcm_id,
          spcm_state: newState
        }

        let spcmAddResp = await Spcms.spcmEdit(this, payload)
        await ApiResponse.responseMessageDisplay(this, spcmAddResp)

        if (spcmAddResp && !spcmAddResp.resp_status) {
          return false
        }

        spcmObj.spcm_state = newState
        this.showModelSpcmStatusUpdate = false
      }
      catch (err) {
        console.error("Exception occurred at spcmStatusUpdate() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * show Spcm Status Update Dialog
     */
    showSpcmStatusUpdateOpenDialog (spcmObj) {
      this.spcmObj = spcmObj
      this.showModelSpcmStatusUpdate = true
    },
    /**
     * showSpcmDeleteDialog
     */
    showSpcmDeleteDialog (spcmObj) {
      try {
        this.spcmObj = spcmObj
        this.showModelSpcmDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showSpcmDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * spcmDelete
     */
    async spcmDelete () {
      try {
        let spcmDelResp = await Spcms.spcmDelete(this, this.spcmObj.spcm_id)
        await ApiResponse.responseMessageDisplay(this, spcmDelResp)

        if (spcmDelResp && !spcmDelResp) {
          this.showModelSpcmDelete = false
          return false
        }

        let index = this.spcmObjList.indexOf(this.spcmObj)
        this.spcmObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelSpcmDelete = false
      }
      catch (err) {
        console.error("Exception occurred at spcmDelete() and Exception:", err.message)
      }
    },
    /**
     * closeSpcmAddModal
     */
    closeSpcmAddModal (spcmAddData) {
      try {
        if (spcmAddData) {
          if (this.spcmObjList && this.spcmObjList.length >= 1) {
            let spcmObjLength = this.spcmObjList.length
            let lastId = this.spcmObjList[spcmObjLength - 1]["id"]
            spcmAddData.id = lastId + 1
          }
          else {
            this.spcmObjList = []
            spcmAddData.id = 11111
          }

          spcmAddData.created_on = moment()
          this.spcmObjList.unshift(spcmAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelSpcmAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closespcmAddModal() and Exception:", err.message)
      }
    },
    /**
     * close Spcm Country Strategy Modal
     */
    closeSpcmCountryStrategyModal (updatedData) {
      try {
        // Update the List with new Information
        for (let i in this.spcmObjList) {
          if (this.spcmObjList[i].spcm_id == updatedData.spcm_id) {
            this.spcmObjList[i] = updatedData
            break
          }
        }

        this.updateInfo = this.updateInfo + 1
      }
      catch (err) {
        console.error("Exception occurred at closeSpcmCountryStrategyModal() and Exception:", err.message)
      }
    },
    /**
     * openReport
     */
    openReport (url) {
      window.open(url, "_blank")
    },
    /**
     * getNotes
     */
    getNotes (data) {
      return data.latest_counselor_notes ? data.latest_counselor_notes.slice(0, 200) + `<br><br><span class='primary-color pointer'>Read More ...</span>` : ""
    },
    /**
     *  Do Copy
     */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")
      document.body.appendChild(dummy)
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
    },
    /**
     * spcm_country_strategyAdd
    */
    spcm_country_strategyAdd (type, item) {
      if (!(this.userData.user_role == "USERROLE11111" || this.userData.user_id == item.counsellor_id)) {
        this.toastMsg = "Warning! Only lead couselor or admin can change this."
        this.toastVariant = "warning"
        this.showToast = true
        return
      }

      try {
        if (type === "FULLPAGE") {
          this.$router.push("/spcm_country_strategyAdd")
        }
        else {
          this.spcmStrategyObj = item
          this.showModelSpcm_Country_StrategyAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at showModelSpcm_Country_StrategyAdd() and Exception:", err.message)
      }
    },
    /**
     * showChangeCounsellor
     */
    showChangeCounsellor (spcmUser) {
      if (!(this.userData.user_role == "USERROLE11111" || this.userData.user_id == spcmUser.counsellor_id)) {
        this.toastMsg = "Warning! Only lead couselor or admin can switch!"
        this.toastVariant = "warning"
        this.showToast = true
        return
      }

      this.propSpcmUser = {
        user_id: spcmUser.user_id,
        stu_name: spcmUser.user_name,
        stu_email: spcmUser.user_email,
        spcm_id: spcmUser.spcm_id,
        counsellor_id: spcmUser.counsellor_id,
        counsellor_name: spcmUser.counsellor_name,
        history: spcmUser.history
      }
      this.showChangeCounsellorModal = true
    },

    /**
     * spcmStudentCounsellorTeam
    */
    spcmStudentCounsellorTeam (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/spcmStudentCounsellor_team/" + this.StudentCounsellorTeamObj.spcm_id)
        }
        else {
          this.StudentCounsellorTeamObj = item
          this.showModelSpcmStudentCounsellorTeam = true
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmStudentCounsellorTeam() and Exception:", err.message)
      }
      // this.$router.push(`/spcmStudentCounsellor_team/${spcmCounsellorId}`)
    },
    /**
     * spcmCategory
     */
    async spcmCategory () {
      try {
        let spcmCategoryListResp = await Spcms.spcmCategoryList(this)
        if (spcmCategoryListResp.resp_status) {
          let categoryObjList = spcmCategoryListResp.resp_data.data
          this.SPCM_CATEGORY_LIST = categoryObjList
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmCategoryList() and Exception:", err.message)
      }
    },
    /**
     * closeStudentCounsellorTeamModal
     */
    closeStudentCounsellorTeamModal (updatedData) {
      try {
        // Update the List with new Information
        for (let i in this.spcmObjList) {
          if (this.spcmObjList[i].spcm_id == updatedData.spcm_id) {
            this.spcmObjList[i] = updatedData
            break
          }
        }

        this.updateInfo = this.updateInfo + 1
        this.showModelSpcmStudentCounsellorTeam = false
      }
      catch (err) {
        console.error("Exception occurred at closeStudentCounsellorTeamModal() and Exception:", err.message)
      }
    },
    /**
     * show Spcm Category Update Dialog
     */
    showSpcmCategoryUpdateOpenDialog (spcmObj) {
      this.spcmObj = spcmObj
      this.new_spcm_category = spcmObj.spcm_category
      this.showModelSpcmCategoryUpdate = true
    },
    /**
     * spcm category Update
     */
    async spcmCategoryUpdate (spcmObj, newCategory) {
      try {
        this.cvLoadingStatus = true

        let payload = {
          spcm_id: spcmObj.spcm_id,
          spcm_category: newCategory
        }

        let spcmAddResp = await Spcms.spcmEdit(this, payload)
        await ApiResponse.responseMessageDisplay(this, spcmAddResp)

        if (spcmAddResp && !spcmAddResp.resp_status) {
          return false
        }

        spcmObj.spcm_category = newCategory
        this.spcmList()
        this.showModelSpcmCategoryUpdate = false
      }
      catch (err) {
        console.error("Exception occurred at spcmCategoryUpdate() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * getBgFor
     */
    getBgFor (category) {
      switch (category) {
        case "Partner":
          return ColorCodes.getBgColor(24)
        case "GIDE-ONE":
          return ColorCodes.getBgColor(3)
        case "Premium":
          return ColorCodes.getBgColor(10)
      }
    },
    /**
     * parse and get the data
     */
    getProgramAndTest (programs) {
      try {
        return JSON.parse(programs)
      }
      catch (e) {
        return {}
      }
    },
    /**
     * goToUserSubscriptions
     */
    goToUserSubscriptions (userid) {
      window.open(`my_billing_history/${userid}`)
    },
    /**
     * getStateColorCode
     */
    getStateColorCode (registrationState = null) {
      if (registrationState && registrationState != 1) {
        return `vertical-align: middle; color: #FFF; background: #FF9900 !important;`
      }
      else {
        return `vertical-align: middle; color: #FFF; background: #e5252c !important;`
      }
    },
    /**
     * view Gide contracts
     */
    viewContract (item) {
      this.gideCounselingContractObj = item
      this.gideCounselingContractObj.blog_id = this.gideContractJSON[item.spcm_category]
      this.showModalGideContracts = true
    },
    /**
     * close GIDECounselingContractModal
     */
    closeGIDECounselingContractModal () {
      try {
        this.showModalGideContracts = false
        this.spcmList()
      }
      catch (err) {
        console.error("Exception occurred at closeGIDECounselingContractModal() and Exception:", err.message)
      }
    },
    /**
     * backendCsvDownload
    */
    async backendCsvDownload () {
      this.spcmList(true)
    }
  }
}
</script>
<style lang="css" scoped>
  .button_align{
    display: inline-flex;
    width: auto;
    border: 0px;
  }
</style>
